const GOLD_1 = '#fbf5db';
const GOLD_2 = '#e7d08e';
const GOLD_3 = '#d8be72';
const GOLD_4 = '#caad59';
const GOLD_5 = '#8d6a00';
const GREY_1 = '#f6f6f6';
const GREY_2 = '#e8e8e8';
const GREY_3 = '#bdbdbd';
const GREY_4 = '#9e9e9e';
const GREY_5 = '#828282';
const GREY_6 = '#4f4f4f';
const GREY_7 = '#333333';
const GREY_8 = '#757575';
/* OLD COLORS - might not be used */
const DEEP_ROSE = '#97203B';
const WILD_LAVENDER = '#5F588F';
const ASPIDISTRA = '#006B5F';
const MONARCH_BLUE = '#067399';
const LEAF_GREEN = '#86A33F';
const SUNSET = '#FDB813';
// RED
// PURPLE
// PINK
// LILAC
// PEACH
// YELLOW
// GREEN
/* NEW COLORS - with brand changes */
const RED = '#FF0050';
const PURPLE = '#9B00FE';
const PINK = '#FFCBDB'; //replacing the existing color '#FF98B9' with #FFCBDB
const LILAC = '#E9CBFF';
const PEACH = '#FECCA7';
const YELLOW = '#FEDC00';
const GREEN = '#04FF7E';
const BLACK = '#000000';
const WHITE = '#FFFFFF';
const Red = '#FF0050';
const Yellow = '#FEDC00';
const Green = '#04FF7E';
const Peach = '#FECCA7';
const Pink = '#FF98B9';
const Purple = '#9B00FE';
const Lilac = '#E9CBFF';
// new color codes
const LIGHT_GREEN = '#82FFC0';
const LIGHT_YELLOW = '#FFF098';
const wildExotic = { 600: '#D0004C', 500: '#E01761', 400: '#E71F68', 300: '#FF7AAB' };
const cobalt = { 600: '#006A88', 500: '#007FA3', 400: '#009DC8', 300: '#00B3E4' };
const jade = { 600: '#006F5C', 500: '#048670', 400: '#00A88D', 300: '#02BB9D' };
const sharpYellow = { 400: '#FEC800', 300: '#FFDF6D' };
const lazyLime = { 400: '#BDD245', 300: '#DEE9A3' };
const plushBlush = { 400: '#F7C2CE', 300: '#FADBE2' };
const black = { 700: '#3C3C3B', 600: '#6D6D6D', 500: GREY_5, 400: GREY_4, 300: GREY_3, 200: GREY_2, 100: GREY_1 };
const blue = '#008BE9';
export const statusColorsInterflora = {
    error: { dark: '#A50400', base: '#E90000', light: '#FCE7E7' },
    warning: { dark: '#8F6B00', base: '#000000', light: '#FFF5E5' },
    success: { dark: '#056000', base: '#2C8500', light: '#ECF9F3' },
};
// Flying Flowers Status Colors
// https://www.figma.com/file/UI4BmbaVYDdLOBd0ZDO7Po/FF-DS_version-2.0.0?node-id=3113%3A64332
export const statusColorsFlyingFlowers = {
    error: { dark: '#AF0508', base: '#E90000', light: '#FF9293' },
    warning: { dark: '#9A3C09', base: '#000000', light: '#F8AE86' },
    success: { dark: '#527735', base: '#2C8500', light: '#C0DAAD' },
};
export const colors = {
    primary: {
        gold1: GOLD_1,
        gold2: GOLD_2,
        gold3: GOLD_3,
        gold4: GOLD_4,
        gold5: GOLD_5,
    },
    secondary: {
        rose: RED,
        lavender: PURPLE,
    },
    grey: {
        grey1: GREY_1,
        grey2: GREY_2,
        grey3: GREY_3,
        grey4: GREY_4,
        grey5: GREY_5,
        grey6: GREY_6,
        grey7: GREY_7,
        grey8: GREY_8,
    },
    siteNoticeColor: {
        BLACK: BLACK,
        PURPLE: PURPLE,
        RED: statusColorsInterflora.error.base,
        GOLD: GOLD_5,
        GREEN: statusColorsInterflora.success.base,
    },
    newsLetterBackground: {
        DEEP_ROSE,
        WILD_LAVENDER,
        ASPIDISTRA,
        MONARCH_BLUE,
        LEAF_GREEN,
        SUNSET,
        RED,
        PURPLE,
        PINK,
        LILAC,
        PEACH,
        YELLOW,
        GREEN,
        WHITE,
        GOLD: GOLD_1,
        GREY: GREY_2,
        LIGHT_GREY: GREY_1,
        DARK_GREY: GREY_6,
        BLACK,
        FF_SHARP_YELLOW: sharpYellow[300],
        FF_PLUSH_BLUSH: plushBlush[300],
        FF_LAZY_LIME: lazyLime[300],
        FF_WHITE: WHITE,
        FF_WILD_EXOTIC: wildExotic[300],
        FF_COBALT: cobalt[300],
        FF_JADE: jade[300],
    },
    spotlightBannerBackround: {
        ASPIDISTRA,
        MONARCH_BLUE,
        DEEP_ROSE,
        WILD_LAVENDER,
        LEAF_GREEN,
        SUNSET,
        RED,
        PURPLE,
        PINK,
        LILAC,
        PEACH,
        YELLOW,
        GREEN,
        WHITE,
        FF_SHARP_YELLOW: sharpYellow[300],
        FF_PLUSH_BLUSH: plushBlush[300],
        FF_LAZY_LIME: lazyLime[300],
        FF_WILD_EXOTIC: wildExotic[500],
        GREY: GREY_2,
        FF_WHITE: WHITE,
        FF_GREY: GREY_2,
        FF_COBALT: cobalt[500],
        FF_JADE: jade[500],
        LIGHT_GREEN,
        LIGHT_YELLOW,
    },
    genericContentHeading: {
        RED,
        GREEN,
        PEACH,
        LILAC,
        PINK,
        PURPLE,
        YELLOW,
        BLACK,
        WHITE,
        LIGHT_GREEN,
        LIGHT_YELLOW,
    },
    background: {
        DEEP_ROSE,
        WILD_LAVENDER,
        ASPIDISTRA,
        MONARCH_BLUE,
        LEAF_GREEN,
        SUNSET,
        RED,
        PURPLE,
        PINK,
        LILAC,
        PEACH,
        YELLOW,
        GREEN,
        WHITE,
        LIGHT_GREY: GREY_1,
        MEDIUM_GREY: GREY_4,
        DARK_GREY: GREY_6,
        GREY_8,
        BLACK,
        FF_SHARP_YELLOW: sharpYellow[400],
        FF_PLUSH_BLUSH: plushBlush[400],
        FF_LAZY_LIME: lazyLime[400],
        FF_WHITE: WHITE,
        FF_WILD_EXOTIC: wildExotic[500],
        FF_COBALT: cobalt[500],
        FF_JADE: jade[500],
        GREY: GREY_2,
        LIGHT_GREEN,
        LIGHT_YELLOW,
        GREY_1,
        GREY_6,
    },
    siteUspsBackgroundText: {
        DEEP_ROSE,
        WILD_LAVENDER,
        ASPIDISTRA,
        MONARCH_BLUE,
        RED,
        PURPLE,
        PINK,
        LILAC,
        PEACH,
        YELLOW,
        GREEN,
        GOLD: GOLD_5,
        BLACK: GREY_6,
        FF_WILD_EXOTIC: wildExotic[500],
        FF_COBALT: cobalt[500],
        FF_JADE: jade[500],
    },
    backgroundText: {
        DEEP_ROSE: WHITE,
        WILD_LAVENDER: WHITE,
        ASPIDISTRA: WHITE,
        MONARCH_BLUE: WHITE,
        LEAF_GREEN: BLACK,
        SUNSET: BLACK,
        RED: BLACK,
        PURPLE: WHITE,
        PINK: BLACK,
        LILAC: BLACK,
        PEACH: BLACK,
        YELLOW: BLACK,
        GREEN: BLACK,
        WHITE: GREY_6,
        LIGHT_GREY: GREY_6,
        DARK_GREY: WHITE,
        BLACK: WHITE,
        FF_SHARP_YELLOW: black[700],
        FF_PLUSH_BLUSH: black[700],
        FF_LAZY_LIME: black[700],
        FF_WHITE: black[700],
        FF_WILD_EXOTIC: WHITE,
        FF_COBALT: WHITE,
        FF_JADE: WHITE,
        GREY_6: WHITE,
    },
    spotlightBannerLinkBackgroundText: {
        DEEP_ROSE: WHITE,
        WILD_LAVENDER: WHITE,
        ASPIDISTRA: WHITE,
        MONARCH_BLUE: WHITE,
        LEAF_GREEN: BLACK,
        SUNSET: BLACK,
        RED: BLACK,
        PURPLE: WHITE,
        PINK: BLACK,
        LILAC: BLACK,
        PEACH: BLACK,
        YELLOW: BLACK,
        GREEN: BLACK,
        WHITE: BLACK,
        LIGHT_GREY: GREY_6,
        DARK_GREY: WHITE,
        BLACK: WHITE,
        FF_SHARP_YELLOW: black[700],
        FF_PLUSH_BLUSH: black[700],
        FF_LAZY_LIME: black[700],
        FF_WHITE: wildExotic[500],
        FF_WILD_EXOTIC: WHITE,
        FF_COBALT: WHITE,
        FF_JADE: WHITE,
    },
    backgroundLink: {
        DEEP_ROSE: WHITE,
        WILD_LAVENDER: WHITE,
        ASPIDISTRA: WHITE,
        MONARCH_BLUE: WHITE,
        LEAF_GREEN: BLACK,
        SUNSET: BLACK,
        RED: BLACK,
        PURPLE: WHITE,
        PINK: BLACK,
        LILAC: BLACK,
        PEACH: BLACK,
        YELLOW: BLACK,
        GREEN: BLACK,
        WHITE: GOLD_5,
        LIGHT_GREY: GOLD_5,
        DARK_GREY: GOLD_1,
        BLACK: GOLD_1,
        FF_SHARP_YELLOW: black[700],
        FF_PLUSH_BLUSH: black[700],
        FF_LAZY_LIME: black[700],
        FF_WHITE: black[700],
    },
    spotlightNavigationBackgroundLink: {
        DEEP_ROSE: WHITE,
        WILD_LAVENDER: WHITE,
        ASPIDISTRA: WHITE,
        MONARCH_BLUE: WHITE,
        LEAF_GREEN: BLACK,
        SUNSET: BLACK,
        RED: BLACK,
        PURPLE: WHITE,
        PINK: BLACK,
        LILAC: BLACK,
        PEACH: BLACK,
        YELLOW: BLACK,
        GREEN: BLACK,
        WHITE: BLACK,
        GREY: black[700],
        FF_SHARP_YELLOW: black[700],
        FF_PLUSH_BLUSH: black[700],
        FF_LAZY_LIME: black[700],
        FF_WILD_EXOTIC: WHITE,
        FF_WHITE: wildExotic[500],
        FF_COBALT: WHITE,
        FF_JADE: WHITE,
    },
    backgroundLinkHover: {
        DEEP_ROSE: GREY_2,
        WILD_LAVENDER: GREY_2,
        ASPIDISTRA: GREY_2,
        MONARCH_BLUE: GREY_2,
        LEAF_GREEN: GREY_7,
        SUNSET: GREY_6,
        // need to add hover colors for new colors
        RED: GREY_7,
        PURPLE: GREY_1,
        PINK: GREY_7,
        LILAC: GREY_6,
        PEACH: GREY_6,
        YELLOW: GREY_6,
        GREEN: GREY_6,
        WHITE: GOLD_4,
        LIGHT_GREY: GOLD_4,
        DARK_GREY: GOLD_2,
        BLACK: GOLD_2,
        // Not Used In Designs
        FF_SHARP_YELLOW: black[600],
        FF_PLUSH_BLUSH: black[600],
        FF_LAZY_LIME: black[600],
        FF_WHITE: black[600],
    },
    spotlightBannerNavigationLinkHover: {
        DEEP_ROSE: GREY_2,
        WILD_LAVENDER: GREY_2,
        ASPIDISTRA: GREY_2,
        MONARCH_BLUE: GREY_2,
        LEAF_GREEN: GREY_7,
        SUNSET: GREY_6,
        // need to add hover colors for new colors
        RED: GREY_7,
        PURPLE: GREY_1,
        PINK: GREY_7,
        LILAC: GREY_6,
        PEACH: GREY_6,
        YELLOW: GREY_6,
        GREEN: GREY_6,
        WHITE: GOLD_4,
        LIGHT_GREY: GOLD_4,
        DARK_GREY: GOLD_2,
        BLACK: GOLD_2,
        // Not Used In Designs
        FF_SHARP_YELLOW: black[600],
        FF_PLUSH_BLUSH: black[600],
        FF_LAZY_LIME: black[600],
        FF_WHITE: black[600],
        FF_WILD_EXOTIC: black[600],
        FF_GREY: black[600],
        FF_COBALT: black[600],
        FF_JADE: black[600],
    },
    imageBackground: {
        WHITE: WHITE,
        FF_WILD_EXOTIC: wildExotic[400],
        FF_COBALT: cobalt[400],
        FF_JADE: jade[400],
    },
    supplementary: {
        black: BLACK,
        white: WHITE,
    },
    // Flying Flowers
    wildExotic,
    cobalt,
    jade,
    sharpYellow,
    lazyLime,
    plushBlush,
    black,
    blue,
    Red,
    Peach,
    Green,
    Pink,
    Purple,
    Lilac,
    Yellow,
};
export const breakPoints = {
    bkpSmall: '480px',
    bkpMedium: '1024px',
    bkpLarge: '1920px',
};
export const spacing = {
    xxs: '4px',
    xs: '8px',
    small: '16px',
    medium: '24px',
    large: '32px',
    xl: '48px',
    xxl: '64px',
    xxxl: '88px',
    super: '120px',
};
export const fontSizes = {
    subtitle: '0.75rem',
    hybrid: '0.875rem',
    small: '1rem',
    medium: '1.25rem',
    large: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
    xxxl: '3.75rem',
    xxxxl: '4rem',
    xxxxxl: '6rem',
};
export const fontWeights = {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
};
const typographyGothamBase = {
    fontFamily: `'Gotham', sans-serif`,
    fontSize: 16,
    lineHeight: '1.5rem',
    color: colors.grey.grey7,
};
export const typographyGotham = {
    xxxxl_bold: {},
    xxxxl: {
        /* a-text_xxxxl new*/
        fontSize: fontSizes.xxxxl,
        fontWeight: fontWeights.medium,
        letterSpacing: '0px',
        lineHeight: '4.5rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    xxxl: {
        /* a-text_xxxl was a-text-h-super*/
        fontSize: fontSizes.xxxl,
        fontWeight: fontWeights.light,
        letterSpacing: '-1.5px',
        lineHeight: '5.75rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxl: {
        /* a-text-h-xxl was a-text-h-xxxl  */
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.medium,
        letterSpacing: '0px',
        lineHeight: '4.5rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xxl_bold: {
        /* a-text_xxl_bold*/
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.medium,
        letterSpacing: '0px',
        lineHeight: '4.5rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    /* old a-text-h-xxl has been removed */
    xl_bold: {
        /* a-text_xl_bold_bold was a-text-h-xl */
        fontSize: fontSizes.xl,
        fontWeight: fontWeights.medium,
        letterSpacing: '0.25px',
        lineHeight: '3rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xl_regular: {
        /* a-text_xl_regular new*/
        fontSize: fontSizes.xl,
        fontWeight: fontWeights.light,
        letterSpacing: '0.25px',
        lineHeight: '3rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    large_bold: {
        /* a-text_large_bold was a-text-h-large */
        fontSize: fontSizes.large,
        fontWeight: fontWeights.medium,
        letterSpacing: '0px',
        lineHeight: '2.5rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    large_regular: {
        /* a-text_large_regular was a-text-body_1*/
        fontSize: fontSizes.large,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px',
        lineHeight: '2.25rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    medium_bold: {
        /* a-text_medium_bold was a-text-h-medium */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.medium,
        letterSpacing: '0.15px',
        lineHeight: '2rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    medium_button: {
        /* a-text_medium_button was a-text-button */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.medium,
        letterSpacing: '1.25px',
        lineHeight: '1.25rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    medium_regular: {
        /* a-text_medium_regular was a-text-body-2 */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px',
        lineHeight: '2rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    small_bold: {
        /* a-text_small_bold was a-text-h-small */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.medium,
        letterSpacing: '0.25px',
        lineHeight: '1.5rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    small_button: {
        /* a-text_small_button was a-text-button-slim */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.medium,
        letterSpacing: '1px',
        lineHeight: '1rem',
        paddingTop: '0px', // '3px', Removed as not used for buttons in design system for Interflora
        paddingBottom: '0px', //'1px',
    },
    small_regular: {
        /* a-text_small_regular was a-text-body-3 */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.regular,
        letterSpacing: '0.1px', // Incorrectly shown as 0.25 in table
        lineHeight: '1.5rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    xs: {
        /* AVOID USING a-text_xs was a-hybrid-nav */
        fontSize: fontSizes.hybrid,
        fontWeight: fontWeights.medium,
        letterSpacing: '1px',
        lineHeight: '1.25rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxs_bold: {
        /* a-text_xxs_bold was a-text-h-subtitle */
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.medium,
        letterSpacing: '0.1px',
        lineHeight: '1.25rem', // '0.75rem', table incorrect
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xxs_regular: {
        /* a-text_xxs_regular was a-text-caption */
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.regular,
        letterSpacing: '0.4px',
        lineHeight: '1.25rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
};
export const typographySpacing = {
    none: {
        marginTop: '0px',
        marginBottom: '0px',
    },
    // Heading Large
    xxxxxl_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xxxxl_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xxxl_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xxl_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xxl_regular_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xxl_bold_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    xl_regular_lg: {
        marginTop: '0px',
        marginBottom: '48px',
    },
    xl_bold_lg: {
        marginTop: '0px',
        marginBottom: '48px',
    },
    large_bold_lg: {
        marginTop: '0px',
        marginBottom: '24px',
    },
    medium_bold_lg: {
        marginTop: '0px',
        marginBottom: '16px',
    },
    small_bold_lg: {
        marginTop: '0px',
        marginBottom: '8px',
    },
    // Heading Small
    xxxxxl_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxxxl_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxxl_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxl_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxl_regular_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxl_bold_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xl_regular_sm: {
        marginTop: '0px',
        marginBottom: '24px',
    },
    xl_bold_sm: {
        marginTop: '0px',
        marginBottom: '24px',
    },
    large_bold_sm: {
        marginTop: '0px',
        marginBottom: '16px',
    },
    medium_bold_sm: {
        marginTop: '0px',
        marginBottom: '8px',
    },
    small_bold_sm: {
        marginTop: '0px',
        marginBottom: '4px',
    },
    // Body Large
    large_regular_lg: {
        marginTop: '0px',
        marginBottom: '64px',
    },
    medium_regular_lg: {
        marginTop: '0px',
        marginBottom: '48px',
    },
    small_regular_lg: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    xxs_bold_lg: {
        marginTop: '0px',
        marginBottom: '16px',
    },
    xxs_regular_lg: {
        marginTop: '0px',
        marginBottom: '16px',
    },
    // Body Small
    large_regular_sm: {
        marginTop: '0px',
        marginBottom: '32px',
    },
    medium_regular_sm: {
        marginTop: '0px',
        marginBottom: '24px',
    },
    small_regular_sm: {
        marginTop: '0px',
        marginBottom: '16px',
    },
    xxs_bold_sm: {
        marginTop: '0px',
        marginBottom: '8px',
    },
    xxs_regular_sm: {
        marginTop: '0px',
        marginBottom: '8px',
    },
};
export const typographyAmithen = {
    xl: {
        fontFamily: 'Amithen',
        fontSize: '6rem',
        fontWeight: fontWeights.light,
        letterSpacing: '0.25px',
        lineHeight: '7.75rem',
        paddingTop: 0,
        paddingBottom: 0,
    },
    large: {
        fontFamily: 'Amithen',
        fontSize: '4.5rem',
        fontWeight: fontWeights.light,
        letterSpacing: '0.25px',
        lineHeight: '6rem',
        paddingTop: 1,
        paddingBottom: 3,
    },
    medium: {
        fontFamily: 'Amithen',
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.regular,
        letterSpacing: '0.25px',
        lineHeight: '4rem',
        paddingTop: 1,
        paddingBottom: 3,
    },
    small: {
        fontFamily: 'Amithen',
        fontSize: fontSizes.xl,
        fontWeight: fontWeights.light,
        letterSpacing: '0.25px',
        lineHeight: '3rem',
        paddingTop: 3,
        paddingBottom: 1,
    },
};
export const typographyMontserratBase = {
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: 14,
    lineHeight: '1.5rem',
    color: colors.black[700],
};
export const typographyMontserrat = {
    xxxxl_bold: {
        /* a-text_xxxxl_bold */
        fontSize: fontSizes.xxxxl,
        fontWeight: fontWeights.bold,
        letterSpacing: '2px',
        lineHeight: '4rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    xxxxl: {
        /* a-text_xxxxl*/
        fontSize: fontSizes.xxxxl,
        fontWeight: fontWeights.extraLight,
        letterSpacing: '-0.2px',
        lineHeight: '4rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    xxxl: {
        /* a-text_xxxl*/
        fontSize: fontSizes.xxxl,
        fontWeight: fontWeights.extraLight,
        letterSpacing: '-0.2px',
        lineHeight: '3.75rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxl: {
        /* a-text-xxl */
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.extraLight,
        letterSpacing: '-0.15px',
        lineHeight: '3rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    xl_bold: {
        /* a-text_xl_bold */
        fontSize: '1.75rem',
        fontWeight: fontWeights.bold,
        letterSpacing: '0px', // '2px',
        lineHeight: '42px', // incorrect in table '3rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    xl_regular: {
        /* a-text_xl_regular */
        fontSize: '1.75rem',
        fontWeight: fontWeights.extraLight,
        letterSpacing: '-0.1px',
        lineHeight: '2rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    large_bold: {
        /* a-text_large_bold */
        fontSize: fontSizes.large,
        fontWeight: fontWeights.bold,
        letterSpacing: '1.5px',
        lineHeight: '2.5rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    large_regular: {
        /* a-text_large_regular */
        fontSize: fontSizes.large,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px',
        lineHeight: '2.25rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    medium_bold: {
        /* a-text_medium_bold */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.bold,
        letterSpacing: '1px',
        lineHeight: '2rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    medium_button: {
        /* a-text_medium_button */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.bold,
        letterSpacing: '1px',
        lineHeight: '1.25rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    medium_regular: {
        /* a-text_medium_regular */
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px',
        lineHeight: '2rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    small_bold: {
        /* a-text_small_bold */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.bold,
        letterSpacing: '0.5px',
        lineHeight: '1.5rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    small_button: {
        /* a-text_small_button */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.bold,
        letterSpacing: '0.5px',
        lineHeight: '1rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    small_regular: {
        /* a-text_small_regular */
        fontSize: fontSizes.small,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px', // '0.25px',
        lineHeight: '1.5rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xs: {
        /* AVOID USING a-text_xs  */
        fontSize: fontSizes.hybrid,
        fontWeight: fontWeights.regular,
        // letterSpacing:
        letterSpacing: '0px', // '1px',
        lineHeight: '1.25rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxs_bold: {
        /* a-text_xxs_bold */
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.bold,
        letterSpacing: '0.5px', // '0.1px',
        lineHeight: '0.75rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xxs_regular: {
        /* a-text_xxs_regular */
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.regular,
        letterSpacing: '0px', // '0.4px',
        lineHeight: '1.25rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
};
// New font Sohne Kraftig and Leicht
export const typographySohneBase = {
    fontFamily: `'Sohne-Kraftig', sans-serif`,
    fontSize: 14, // Base font got changes due to material UI upgrade from 16 to 14
    lineHeight: '1.5rem',
    color: colors.grey.grey7,
};
export const typographySohne = {
    xxxxxl: {
        /* a-text_xxxxxl*/
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.xxxxxl,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0px',
        lineHeight: '7rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxxxl: {
        /* a-text_xxxxl*/
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.xxxxl,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0px',
        lineHeight: '5rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxxl: {
        /* a-text_xxxl*/
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.xxxxl,
        fontWeight: fontWeights.light,
        letterSpacing: '0px',
        lineHeight: '5rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxl: {
        /* a-text-xxl */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.light,
        letterSpacing: '0.6px',
        lineHeight: '4rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xxl_bold: {
        /* a-text_xxl_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.xxl,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.6px', // '2px',
        lineHeight: '4rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    xl_bold: {
        /* a-text_xl_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.xl,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.6px', // '2px',
        lineHeight: '3rem', // incorrect in table '3rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xl_regular: {
        /* a-text_xl_regular */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.xl,
        fontWeight: fontWeights.light,
        letterSpacing: '0.6px',
        lineHeight: '3rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    large_regular: {
        /* a-text_large_regular */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.large,
        fontWeight: fontWeights.light,
        letterSpacing: '0.2px',
        lineHeight: '2.25rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    large_bold: {
        /* a-text_large_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.large,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.6px',
        lineHeight: '2.25rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    medium_bold: {
        /* a-text_medium_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.8px',
        lineHeight: '2rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    medium_button: {
        /* a-text_medium_button */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.8px',
        lineHeight: '1.25rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    medium_regular: {
        /* a-text_medium_regular */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.light,
        letterSpacing: '0px',
        lineHeight: '2rem',
        paddingTop: '1px',
        paddingBottom: '3px',
    },
    small_bold: {
        /* a-text_small_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.small,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.8px',
        lineHeight: '1.5rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    small_button: {
        /* a-text_small_button */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.small,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.8px',
        lineHeight: '1rem',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    small_regular: {
        /* a-text_small_regular */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.small,
        fontWeight: fontWeights.light,
        letterSpacing: '0.4px', // '0.25px',
        lineHeight: '1.75rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xs: {
        /* AVOID USING a-text_xs  */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.hybrid,
        fontWeight: fontWeights.semiBold,
        // letterSpacing:
        letterSpacing: '0.2px', // '1px',
        lineHeight: '1.5rem',
        paddingTop: '3px',
        paddingBottom: '1px',
    },
    xxs_bold: {
        /* a-text_xxs_bold */
        fontFamily: `'Sohne-Kraftig', sans-serif`,
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.2px', // '0.1px',
        lineHeight: '1.5rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    xxs_regular: {
        /* a-text_xxs_regular */
        fontFamily: `'Sohne-Leicht', sans-serif`,
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.light,
        letterSpacing: '0.2px', // '0.4px',
        lineHeight: '1.5rem',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
};
export const hideScrollbars = {
    /* Hide scrollbar for IE, Edge and Firefox */
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};
